import { IStackTokens, Link, Stack } from "@fluentui/react";
import { ChallengeType } from "../api/Captcha";
import { useLocale } from "../localization";

interface CaptchaAreaProps {
    challengeType: ChallengeType,
    challengeString: string,
    inputCallback: (arg: string) => void,
    fetchCallback: (type: ChallengeType) => void
}

export interface CaptchaAreaState {
    challengeId: string,
    challengeString: string,
    challengeType: ChallengeType
}

export interface CaptchaAreaStrings {
    audioCaptchaInstructions: string,
    visualCaptchaInstructions: string
    newCaptchaLabel: string,
    switchToAudioLabel: string,
    switchToVisualLabel: string
}

function CaptchaArea (props:CaptchaAreaProps) {
    const stackTokens: IStackTokens = { childrenGap: 5 };
    const strings = useLocale().strings.captchaArea;
    const captchaInstructions: string = props.challengeType === "audio" ? strings.audioCaptchaInstructions : strings.visualCaptchaInstructions;

    return (
        <Stack className="captcha-area" tokens={stackTokens}>
            <Stack.Item>
                <b>{captchaInstructions}</b>
            </Stack.Item>
            <Stack.Item>
                <Link className="captcha-btn-left" onClick={() => {
                    props.fetchCallback(props.challengeType);
                }}>{strings.newCaptchaLabel}</Link>
                |
                <Link className="captcha-btn-right" onClick={() => {
                    props.fetchCallback(props.challengeType === 'audio' ? "visual" : "audio");
                }}>{ props.challengeType === 'audio' ? strings.switchToVisualLabel : strings.switchToAudioLabel }</Link>
            </Stack.Item>
            <Stack.Item>
            { props.challengeType === "visual" && <img className="captcha-img" src={"data:image/png;base64, " + props.challengeString} alt="captcha" /> }
            { props.challengeType === "audio" && <audio controls src={'data:audio/mp3;base64, ' + props.challengeString} /> }
            </Stack.Item>
            <Stack.Item>
                <input className="captcha-input" onChange={(e) => {
                    props.inputCallback(e.target.value);
                }} />
            </Stack.Item>
        </Stack>
    )
}

export default CaptchaArea;