import { useState } from "react";
import { NetworkTestProgress, NetworkTestStep } from "../../networkTest/public";
import BrowserTestResultsPane from "./BrowserTestResultsPane";
import { CallTestResultsPane, CallType } from "./CallTestResultsPane";
import DeviceTestResultsPane from "./DeviceTestResultsPane";

interface TestResultsProps {
  results?: NetworkTestProgress;
}

export interface TestResultsStrings {
  notApplicable: string;
}

function TestResults(props: TestResultsProps) {
  const [resultsCollapsed, setResultsCollapsed] = useState(true);

  const _renderToggleButtonIcon = () => {
    return resultsCollapsed ? (
      <i className="ms-Icon ms-Icon--ChevronDown"></i>
    ) : (
      <i className="ms-Icon ms-Icon--ChevronUp"></i>
    );
  };

  const _toggleResults = () => {
    setResultsCollapsed(!resultsCollapsed);
  };

  if (!props.results) {
    return <div className="ms-Grid-col ms-sm5 ms-lg7 ms-xl5"> N/A </div>;
  }

  if (props.results.error) {
    return (
      <div className="ms-Grid-col ms-sm5 ms-lg7 ms-xl5">
        {props.results.error.message}
      </div>
    );
  } else if (!props.results.successMessage) {
    return <div className="ms-Grid-col ms-sm5 ms-lg7 ms-xl5"> N/A </div>;
  }

  return (
    <div className="testResults ms-Grid-col ms-sm5 ms-lg7 ms-xl5">
      <div className="ms-Grid-col ms-sm10 ms-lg11">
        <div className="primary-text">
        {props.results.successMessage}
        </div>
        <div className={resultsCollapsed ? "collapsed" : "secondary-text"}>
          {_renderResults(props.results)}
        </div>
      </div>
      <div className="ms-Grid-col ms-sm2 ms-lg1">
        <button className="results-button" onClick={_toggleResults}>
          {_renderToggleButtonIcon()}
        </button>
      </div>
    </div>
  );
}

function _renderResults(testProgress: NetworkTestProgress) {
  switch (testProgress.step) {
    case NetworkTestStep.Browser:
      return (
        <BrowserTestResultsPane
          browserDetails={{
            os: testProgress.os,
            engine: testProgress.engine,
            version: testProgress.version,
          }}
        />
      );

    case NetworkTestStep.MediaDevices:
      return (
        <DeviceTestResultsPane
          mediaDevices={{
            camera: testProgress.camera,
            speaker: testProgress.speaker,
            microphone: testProgress.microphone,
          }}
        />
      );

    case NetworkTestStep.AudioCall:
    case NetworkTestStep.VideoCall:
      return testProgress.networkMediaTelemetry ? (
        <CallTestResultsPane
          callType={
            testProgress.step === NetworkTestStep.VideoCall
              ? CallType.Video
              : CallType.Audio
          }
          networkMediaTelemetry={testProgress.networkMediaTelemetry}
        />
      ) : (
        <div>N/A</div>
      );

    default:
      return null;
  }
}

export default TestResults;
