import React from "react";
import { useLocale } from "../localization";
import { formatStringWithInlineElements } from "../utils/LocalizationUtils";

export interface AppHeaderStrings {
  titleLabel: string;
  descriptionBody: string;
  descriptionNetworkConditionsLink: string;
  descriptionAcsLink: string;
  descriptionFooter: string;
}

function AppHeader() {
  const strings = useLocale().strings.appHeader;

  return (
    <header className="header">
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm7 ms-lg6">
            <h1>{strings.titleLabel}</h1>
            <p>
              {formatStringWithInlineElements(strings.descriptionBody, {
                element1: <a href="https://docs.microsoft.com/en-us/azure/communication-services/concepts/voice-video-calling/network-requirements">
                {strings.descriptionNetworkConditionsLink}
                </a>,
                element2: <a href="https://docs.microsoft.com/en-us/azure/communication-services/overview">
                {strings.descriptionAcsLink}
                </a>
              })}
              <br />
              <br />
              {strings.descriptionFooter}
            </p>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AppHeader;
