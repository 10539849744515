import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import config from "../../config.json";

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: config.aiConnectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export {reactPlugin, appInsights};