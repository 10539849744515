import React, { useEffect, useState } from "react";
import TestCase from "./TestCase";
import ProgressBar from "./ProgressBar"
import {
  AzureCommunicationTokenCredential,
  CommunicationTokenCredential,
} from "@azure/communication-common";
import { useLocale } from "../localization";
import { MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";
import { fetchTokenResponse } from "../api/Token";
import { fetchCaptcha, solveCaptcha } from "../api/Captcha";
import NetworkTestRunner from "../networkTest/NetworkTestRunner";
import { NetworkTestProgress, NetworkTestStarted, NetworkTestStep } from "../networkTest/public";
import { CallClient } from "@azure/communication-calling";
import EventEmitter from "events";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import CaptchaArea, { CaptchaAreaState } from "./CaptchaArea";

export interface NetworkDiagnosticScreenStrings {
  startTestLabel: string;
  testNameLabel: string;
  testStatusLabel: string;
  testResultsLabel: string;
  browserTestName: string;
  browserTestDescription: string;
  devicesTestName: string;
  devicesTestDescription: string;
  configTestName: string;
  configTestDescription: string;
  audioTestName: string;
  audioTestDescription: string;
  videoTestName: string;
  videoTestDescription: string;
  networkTestIdLabel: string;
}

const NetworkDiagnosticScreen = () => {
  const appInsights = useAppInsightsContext();

  const _networkTest = new NetworkTestRunner(new CallClient(), new EventEmitter());
  const NUM_TEST_CASES = Object.keys(NetworkTestStep).filter((item) => {
    return isNaN(Number(item));
  }).length;

  const [testCasesProgress, _setTestCasesProgress] = useState<NetworkTestProgress[]>(
    new Array(NUM_TEST_CASES).fill({})
  ); //Initialize with Empty State with no results
  const testCasesProgressRef = React.useRef(testCasesProgress);
  const setTestCasesProgress = (data: NetworkTestProgress[]) => {
    testCasesProgressRef.current = data;
    _setTestCasesProgress(data);
  };
  
  const [diagnosticInProgress, _setDiagnosticInProgress] =
    useState<boolean>(false);
  const diagnosticInProgressRef = React.useRef(diagnosticInProgress);
  const setDiagnosticInProgress = (data: boolean) => {
    diagnosticInProgressRef.current = data;
    _setDiagnosticInProgress(data);
  };

  const [showCaptcha, setShowCaptcha] = useState<boolean>(true);
  const [captchaSolution, setCaptchaSolution] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [captchaState, setCaptchaState] = useState<CaptchaAreaState>({
    challengeId: "",
    challengeString: "",
    challengeType: "visual"
  });

  async function getCaptcha(type: "audio" | "visual") {
    try {
      const state = await fetchCaptcha(type);
      setCaptchaState(state);
    } catch (err) {
      setShowCaptcha(false);
    }
  }

  //loads the captcha initially
  useEffect(() => {
      getCaptcha("visual");
  }, []);

  const handleTestProgress = (networkTestProgress: NetworkTestProgress) => {
    const currentTestStep = networkTestProgress.step;
    if (currentTestStep === NetworkTestStep.Done) {
      setDiagnosticInProgress(false);
      
      if(networkTestProgress.error) {
        const error = networkTestProgress.error;
        appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
        setErrorMessage(`${error.name}: ${error.message}`);
      }

      return;
    }

    if (currentTestStep === NetworkTestStep.Init && networkTestProgress.status === "Complete") {
      appInsights.trackTrace({
        message: "Network Test Initialized Successfully",
        severityLevel: SeverityLevel.Information,
        properties: {
          id: networkTestProgress.id
        }
      });
    }

    let currentTestState = testCasesProgressRef.current;
    const newStates = currentTestState.slice();

    newStates[currentTestStep] = networkTestProgress;
    setTestCasesProgress(newStates);
  };

  const startNetworkTest = async () => {
    setErrorMessage(""); //clear the error

    try {
      if(showCaptcha) {      
        //If empty
        if(!captchaSolution) {
          setErrorMessage("Could not start test - Please solve the challenge before continuing");
          return;
        }
        const resp = await solveCaptcha(captchaState.challengeType, captchaState.challengeId, captchaSolution);
        if(!resp.solved) {
          setErrorMessage(resp.error);
          await getCaptcha(captchaState.challengeType);
          return;
        }
        else {
          setShowCaptcha(false);
        }
      }

      appInsights.trackTrace({
        message: "Begin Network Test",
        severityLevel: SeverityLevel.Information,
      });
      setDiagnosticInProgress(true);
      setTestCasesProgress(new Array(NUM_TEST_CASES).fill({})); //Ensures we start with a fresh slate

      const devicePermissionsAvailable = await _networkTest.checkNetworkTestRunnable();
      if(!devicePermissionsAvailable) {
        const errorMessage = "Could not start test - permission to access microphone/camera required. Please grant access then try again.";
        setErrorMessage(errorMessage);
        appInsights.trackException({ error: new Error(errorMessage), severityLevel: SeverityLevel.Error });

        return;
      }

      const { token } = await fetchTokenResponse();
      let tokenCredential: CommunicationTokenCredential = new AzureCommunicationTokenCredential(token);
      _networkTest.on("testProgress", handleTestProgress);
      await _networkTest.runNetworkTest(tokenCredential);
    } catch (e) {
      if (e instanceof Error) {
        appInsights.trackException({ error: e, severityLevel: SeverityLevel.Error });
      }
      setErrorMessage("Test stopped prematurely - an internal error has occurred.");
    } finally {
      setDiagnosticInProgress(false);
      _networkTest.off("testProgress", handleTestProgress);

      const networkTestStarted = testCasesProgress[NetworkTestStep.Init];
      appInsights.trackTrace({
        message: "End Network Test",
        severityLevel: SeverityLevel.Information,
        properties: {
          id: networkTestStarted ? (networkTestStarted as NetworkTestStarted).id : "id not set"
        }
      });
    }
  };

  const strings = useLocale().strings.diagnosticTestScreen;

  return (
    <div className="main">
      <div className="ms-Grid">
      {showCaptcha && 
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-lg4 ms-xl2">
            <CaptchaArea 
              challengeString={captchaState.challengeString}
              challengeType={captchaState.challengeType}
              inputCallback={(arg:string) => setCaptchaSolution(arg)} 
              fetchCallback={(type: "audio" | "visual") => { getCaptcha(type)} }/>
          </div>
        </div>}
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-lg6">
            {
                errorMessage &&
                <MessageBar className="errorMessageBar"
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                >
                {errorMessage}
                </MessageBar>
            }
            <PrimaryButton id="start-test-button" text={strings.startTestLabel} disabled={diagnosticInProgress} onClick={startNetworkTest} />
          </div>
        </div>
      </div>
      <div className="separator-dark" />
      <ProgressBar showProgressBar={diagnosticInProgress} currentTestProgress={testCasesProgress} />
      <div className="ms-Grid" dir="ltr">
        <div id="resultsHeader" className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm4 ms-lg3 ms-xl2">
            <b>{strings.testNameLabel}</b>
          </div>
          <div className="ms-Grid-col ms-sm3 ms-lg2 ms-xl1">
            <b>{strings.testStatusLabel}</b>
          </div>
          <div className="ms-Grid-col ms-sm5 ms-lg7 ms-xl5">
            <b>{strings.testResultsLabel}</b>
          </div>
        </div>
        <RowDivider />
        <TestCase
          name={strings.browserTestName}
          description={strings.browserTestDescription}
          testCaseProgress={testCasesProgress[1]}
        />
        <RowDivider />
        <TestCase
          name={strings.devicesTestName}
          description={strings.devicesTestDescription}
          testCaseProgress={testCasesProgress[2]}
        />
        <RowDivider />
        <TestCase
          name={strings.audioTestName}
          description={strings.audioTestDescription}
          testCaseProgress={testCasesProgress[3]}
        />
        <RowDivider />
        <TestCase
          name={strings.videoTestName}
          description={strings.videoTestDescription}
          testCaseProgress={testCasesProgress[4]}
        />
        <RowDivider />
        {(testCasesProgress[0] as NetworkTestStarted).id && 
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg9 ms-xl5">
            {`${strings.networkTestIdLabel} ${(testCasesProgress[0] as NetworkTestStarted).id}`}
          </div>
        </div>}
      </div>
    </div>
  );
}

function RowDivider() {
  return (
    <div className="ms-Grid-row">
        <div className="separator-light ms-Grid-col ms-sm12 ms-lg11 ms-xl8"></div>
    </div>
  );
}

export default NetworkDiagnosticScreen;