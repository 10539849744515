import React from "react";
import { useLocale } from "../localization";
import { formatStringWithInlineElements } from "../utils/LocalizationUtils";

export interface AppFooterStrings {
  title: string;
  termsLinkLabel: string;
  privacyLinkLabel: string;
  copyrightLabel: string;
  previewTitle: string;
  previewLabel: string;
  previewLinkLabel: string;
}

function AppFooter() {
  const strings = useLocale().strings.appFooter;

  return (
    <footer className="footer">
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm7 ms-lg6">
            <p>
              <b>{strings.previewTitle}</b><br/>
              {formatStringWithInlineElements(strings.previewLabel, {
                element: <a href="https://azure.microsoft.com/en-us/support/legal/preview-supplemental-terms/">
                {strings.previewLinkLabel}
                </a>
              })}
            </p>
            <p>
              <b>{strings.title}</b>
              <br />
              <a href="https://www.microsoft.com/legal/terms-of-use">
                {strings.termsLinkLabel}
              </a>
              <br />
              <a href="https://privacy.microsoft.com/privacystatement">
                {strings.privacyLinkLabel}
              </a>
              <br />
              <br />
              {strings.copyrightLabel}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default AppFooter;
