import { useLocale } from "../../localization";
import { formatString } from "../../utils/LocalizationUtils";

type MediaDevices = {
  camera: {
    available?: boolean | undefined;
  };
  speaker: {
    available?: boolean | undefined;
  };
  microphone: {
    available?: boolean | undefined;
  };
};

export interface DeviceTestResultsStrings {
  speakerLabel: string;
  microphoneLabel: string;
  cameraLabel: string;
  availableLabel: string;
  unavailableLabel: string;
}

interface DeviceTestResultsProps {
  mediaDevices: MediaDevices;
}

function DeviceTestResultsPane(props: DeviceTestResultsProps) {
  const mediaDevices: MediaDevices = props.mediaDevices;
  const strings = useLocale().strings.deviceTestResults;

  return (
    <div>
      <ul className="no-bullets">
        <li>
          {formatString(strings.speakerLabel, {status: mediaDevices.speaker.available ? strings.availableLabel : strings.unavailableLabel})}
        </li>
        <li>
          {formatString(strings.microphoneLabel, {status: mediaDevices.microphone.available ? strings.availableLabel : strings.unavailableLabel})}
        </li>
        <li>
          {formatString(strings.cameraLabel, {status: mediaDevices.camera.available ? strings.availableLabel : strings.unavailableLabel})}
        </li>
      </ul>
    </div>
  );
}

export default DeviceTestResultsPane;
