import React from "react";
import { useLocale } from "../../localization";
import { MetricSummary, NetworkMediaTelemetry } from "../../networkTest/public";
import { formatString, formatStringWithInlineElements } from "../../utils/LocalizationUtils";
import ResultsTooltip from "./ResultsTooltip";

enum CallType {
  Video = "Video",
  Audio = "Audio",
}

export interface CallTestResultsStrings {
  sendingLabel: string;
  receivingLabel: string;
  jitterLabel: string;
  jitterTooltip: string;
  jitterUnit: string;
  rttLabel: string;
  rttTooltip: string;
  rttUnit: string;
  packetLossLabel: string;
}

interface CallTestResultsDisplayProps {
  callType: CallType;
  networkMediaTelemetry: NetworkMediaTelemetry;
}

function adaptAudioTelemetry(networkMediaTelemetry: NetworkMediaTelemetry) {
  return {
    jitter: {
      metrics: networkMediaTelemetry.jitter.audio,
    },
    packets: {
      received: {
        lost: networkMediaTelemetry.packets.audioReceivedLost,
        success: networkMediaTelemetry.packets.audioReceived,
      },
      sent: {
        lost: networkMediaTelemetry.packets.audioSentLost,
        success: networkMediaTelemetry.packets.audioSent,
      },
    },
    rtt: {
      received: {
        metrics: networkMediaTelemetry.rtt.audioReceived,
      },
      sent: {
        metrics: networkMediaTelemetry.rtt.audioSent,
      },
    },
  };
}

function adaptVideoTelemetry(networkMediaTelemetry: NetworkMediaTelemetry) {  
  return {
    jitter: {
      metrics: networkMediaTelemetry.jitter.video,
    },
    packets: {
      received: {
        lost: networkMediaTelemetry.packets.videoReceivedLost,
        success: networkMediaTelemetry.packets.videoReceived,
      },
      sent: {
        lost: networkMediaTelemetry.packets.videoSentLost,
        success: networkMediaTelemetry.packets.videoSent,
      },
    },
    rtt: {
      received: {
        metrics: networkMediaTelemetry.rtt.videoReceived,
      },
      sent: {
        metrics: networkMediaTelemetry.rtt.videoSent,
      },
    },
  };
}

function CallTestResultsPane(props: CallTestResultsDisplayProps) {
  const networkMediaTelemetry: NetworkMediaTelemetry =
    props.networkMediaTelemetry!;

  const strings = useLocale().strings.callTestResults;
  const adaptedTelemetry =
    props.callType === CallType.Video
      ? adaptVideoTelemetry(networkMediaTelemetry)
      : adaptAudioTelemetry(networkMediaTelemetry);

  return (
    <div>
      <ul className="no-bullets">
        <li>
          <MetricSummaryResult
            label={<ResultsTooltip label={strings.jitterLabel} tooltipContent={strings.jitterTooltip} />}
            metric={adaptedTelemetry.jitter.metrics}
            unit={strings.jitterUnit}
          />
        </li>
        <li>
          <PacketResult
            type="sending"
            label={formatString(strings.sendingLabel, {
              callType: props.callType,
              metric: strings.packetLossLabel
            })}
            metric={adaptedTelemetry.packets.sent}
          />
        </li>
        <li>
          <PacketResult
            type="receiving"
            label={formatString(strings.receivingLabel, {
              callType: props.callType,
              metric: strings.packetLossLabel
            })}
            metric={adaptedTelemetry.packets.received}
          />
        </li>
        <li>
          <MetricSummaryResult
            label={formatStringWithInlineElements(strings.sendingLabel, {
              callType: props.callType,
              metric: <ResultsTooltip label={strings.rttLabel} tooltipContent={strings.rttTooltip} />
            })}
            metric={adaptedTelemetry.rtt.sent.metrics}
            unit={strings.rttUnit}
          />
        </li>
        <li>
          <MetricSummaryResult
            label={formatStringWithInlineElements(strings.receivingLabel, {
              callType: props.callType,
              metric: <ResultsTooltip label={strings.rttLabel} tooltipContent={strings.rttTooltip} />
            })}
            metric={adaptedTelemetry.rtt.received.metrics}
            unit={strings.rttUnit}
          />
        </li>
      </ul>
    </div>
  );
}

interface MetricSummaryResultProps {
  label: string | JSX.Element;
  metric?: MetricSummary;
  unit: string;
}

export interface MetricSummaryResultStrings {
  outputLabelAvg: string;
  outputLabelMinMax: string;
  notAvailable: string;
}

function metricToString(metric: number, wholeNumber:boolean = false): string {
  if(metric) {
    return wholeNumber ? metric.toString() : metric.toFixed(1);
  }
  else {
    return wholeNumber ? "0" : "0.0";
  }
}

function MetricSummaryResult(props: MetricSummaryResultProps) {
  const strings = useLocale().strings.metricSummaryResult;
  const outputAvg = props.metric
    ? formatString(strings.outputLabelAvg, {
        avg: metricToString(props.metric.mean),
        units: props.unit
      })
    : strings.notAvailable;

  const outputMinMax = props.metric
    ? formatString(strings.outputLabelMinMax, {
        min: metricToString(props.metric.minimum),
        max: metricToString(props.metric.maximum),
        units: props.unit,
      })
    : strings.notAvailable;
  
  if (outputAvg === strings.notAvailable || outputMinMax === strings.notAvailable) {
    return (
      <div>
        {props.label}: {strings.notAvailable}
      </div>
    );
  } else {
    return (
      <div>
        {props.label}: {outputAvg} {outputMinMax}
      </div>
    );
  }
}

interface PacketResultProps {
  type: "sending" | "receiving";
  label: string;
  metric: {
    lost?: number;
    success?: number;
  };
}

export interface PacketResultStrings {
  sentOutputLabel: string;
  receivedOutputLabel: string;
  notAvailable: string;
}

function PacketResult(props: PacketResultProps) {
  const strings = useLocale().strings.packetResult;
  const output = formatString(props.type === "sending" ? strings.sentOutputLabel : strings.receivedOutputLabel, {
    lost:
      props.metric.lost !== undefined
        ? metricToString(props.metric.lost, true)
        : strings.notAvailable,
    success:
      props.metric.success !== undefined
        ? metricToString(props.metric.success, true)
        : strings.notAvailable,
  });
  return (
    <div>
      {props.label}: {output}
    </div>
  );
}

export { CallType, CallTestResultsPane };
