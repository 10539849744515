import { MediaStatsAggregation } from "@azure/communication-calling";
import { MetricSummary } from "../networkTest/public";

export function getRoundedAverage(sum: number, count: number, averageNum: number = 100):number {
  return Math.round(((sum / count) + Number.EPSILON) * averageNum) / averageNum;
}

export function getMetricSummary(aggregation: MediaStatsAggregation):MetricSummary {
  return {
      minimum: aggregation.min[0] as number,
      maximum: aggregation.max[0] as number,
      mean: getRoundedAverage(aggregation.sum[0] as number, aggregation.count[0] as number)
  }
}