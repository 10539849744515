import { DeviceCompatibility, InCallDiagnostics, MediaStatsReportSummary, PreCallDiagnosticsResult } from "@azure/communication-calling";
import { detect } from "detect-browser";
import EventEmitter from "events";
import { getMetricSummary } from "../utils/AppUtils";
import { BrowserTest, CallTest, CallTestResults, MediaDevicesTest, NetworkMediaTelemetry, NetworkTestError, NetworkTestStep } from "./public";

class PreCallApiToNetworkTestAdapter {
    private _eventEmitter: EventEmitter;
    private _preCallResults: PreCallDiagnosticsResult;

    constructor(eventEmitter: EventEmitter, preCallResults: PreCallDiagnosticsResult) {
        this._eventEmitter = eventEmitter;
        this._preCallResults = preCallResults;
    }

    public getTestId(): string {
        return this._preCallResults.id;
    }
    
    public async getBrowserTestResults():Promise<BrowserTest> {
        const browserTest:BrowserTest = {
            step: NetworkTestStep.Browser,
            status: 'Running',
            name: 'unknown',
            version: 'unknown',
            os: 'unknown',
            engine: 'unknown',
            formFactor: 'unknown'
        };
        this._eventEmitter.emit('testProgress', Object.assign({}, browserTest)); //make this a unique one
    
        const browserSupportResult:DeviceCompatibility | undefined = await this._preCallResults.browserSupport; //this does not throw an errors when it fails.
        if(browserSupportResult) {
            browserTest.status = (browserSupportResult.browser === "Supported" && browserSupportResult.os === "Supported") ? "Pass" : "Fail";
            if(browserTest.status === "Pass") {
                browserTest.successMessage = "Browser is supported";
                browserTest.supported = true;
            }
            else {
                browserTest.status = "Fail";
                browserTest.successMessage = "Browser is not supported";
                browserTest.supported = false;
            }
            
            try {
                const browserDetails = detect();
                if(browserDetails) {
                    browserTest.engine = browserDetails.name;
                    browserTest.version = browserDetails.version!;
                    browserTest.os = browserDetails.os!;
                }
            }
            catch (e) {
                console.error("Unable to display browser details");
                //This library is temporary until we start getting that info back from PreCall
            }
        }
        else {
            browserTest.status = "Fail";
            browserTest.successMessage = "Unable to detect browser";
        }
    
        this._eventEmitter.emit('testProgress', browserTest);
        return browserTest;
    }

    public async getMediaDevicesTestResults():Promise<MediaDevicesTest> {
        const mediaDevicesResult:MediaDevicesTest = {
            step: NetworkTestStep.MediaDevices,
            status: 'Running',
            camera: {
                available: false
            },
            microphone: {
                available: false
            },
            speaker: {
                available: false
            }
        }
        this._eventEmitter.emit('testProgress', Object.assign({}, mediaDevicesResult));

        const access = await this._preCallResults.deviceAccess;
        if(!access.audio && !access.video) {
            mediaDevicesResult.status = "Fail";
            return mediaDevicesResult;
        }
    
        const enumeration = await this._preCallResults.deviceEnumeration;
        mediaDevicesResult.camera.available = access.video ? enumeration.camera === "Available" : false;
        mediaDevicesResult.microphone.available = access.audio ? enumeration.microphone === "Available" : false;
        mediaDevicesResult.speaker.available = access.audio ? enumeration.speaker === "Available" : false;

        mediaDevicesResult.status = "Pass";
        mediaDevicesResult.successMessage = "Device availability test passed";

        if(access.audio) {
            if(!mediaDevicesResult.microphone.available || !mediaDevicesResult.speaker.available) {
                mediaDevicesResult.status = "Fail";
                mediaDevicesResult.successMessage = "Device availability test failed";
            }
        }

        //We should only fail camera, if video permission was given but it doesn't detect it
        if(access.video && !mediaDevicesResult.camera.available) {
            mediaDevicesResult.status = "Fail";
            mediaDevicesResult.successMessage = "Device availability test failed";
        }

        this._eventEmitter.emit('testProgress', mediaDevicesResult);
        return mediaDevicesResult;
    }

    public async getCallTestResults():Promise<CallTestResults> {
        const audioCallResults:CallTest = {
            step: NetworkTestStep.AudioCall,
            status: 'Running',
            callSuccess: false
        };
        this._eventEmitter.emit('testProgress', Object.assign({}, audioCallResults));

        const videoCallResults:CallTest = {
            step: NetworkTestStep.VideoCall,
            status: 'Running',
            callSuccess: false
        };
        this._eventEmitter.emit('testProgress', Object.assign({}, videoCallResults));

        const audioTelemetry: NetworkMediaTelemetry = {
            jitter: {
                audio: {
                    mean: 0,
                    maximum: 0,
                    minimum: 0,
                },
                unit: 'milliseconds'
            },
            rtt: {
                audioSent: {
                    mean: 0,
                    maximum: 0,
                    minimum: 0,
                },
                audioReceived: {
                    mean: 0,
                    maximum: 0,
                    minimum: 0,
                },
                unit: 'milliseconds'
            },
            packets: {
                audioSent: 0,
                audioSentLost: 0,
                audioReceived: 0,
                audioReceivedLost: 0,
            },
        };
    
        const videoTelemetry: NetworkMediaTelemetry = {
            jitter: {
                video: {
                    mean: 0,
                    maximum: 0,
                    minimum: 0,
                },
                unit: 'milliseconds'
            },
            rtt: {
                videoSent: {
                    mean: 0,
                    maximum: 0,
                    minimum: 0,
                },
                videoReceived: {
                    mean: 0,
                    maximum: 0,
                    minimum: 0,
                },
                unit: 'milliseconds'
            },
            packets: {
                videoSent: 0,
                videoSentLost: 0,
                videoReceived: 0,
                videoReceivedLost: 0,
            },
        };

        try {
            //start collecting media Stats
            let collector;
            
            //Inner try/catch block for MediaStats collector
            try {
                collector = (await this._preCallResults.callMediaStatistics)!.createCollector({
                    aggregationInterval: 200,
                    dataPointsPerAggregation: 1,
                });
            }
            catch(e) {
                //We can probably still continue even if we cannot collect media stats
                console.error("Unable to collect media stats");
            }
        
            if(collector) {
                collector.on("summaryReported", (mediaStats: MediaStatsReportSummary) => {
                    const sentAudioStats = mediaStats.audio.send[0];
                    const receivedAudioStats = mediaStats.audio.receive[0];

                    const sentVideoStats = mediaStats.video.send[0];
                    const receivedVideoStats = mediaStats.video.receive[0];

                    if(sentAudioStats) {
                        audioTelemetry.rtt.audioSent = getMetricSummary(sentAudioStats.pairRttInMs?.aggregation!);
                        audioTelemetry.packets.audioSent = sentAudioStats.packetsPerSecond?.aggregation?.sum[0] as number;
                        audioTelemetry.packets.audioSentLost = sentAudioStats.packetsLostPerSecond?.aggregation?.sum[0] as number;
                    }

                    if(receivedAudioStats) {
                        audioTelemetry.jitter.audio = getMetricSummary(receivedAudioStats.jitterInMs?.aggregation!);
                        audioTelemetry.rtt.audioReceived = getMetricSummary(receivedAudioStats.pairRttInMs?.aggregation!);
                        audioTelemetry.packets.audioReceived = receivedAudioStats.packetsPerSecond?.aggregation?.sum[0] as number;
                        audioTelemetry.packets.audioReceivedLost = receivedAudioStats.packetsLostPerSecond?.aggregation?.sum[0] as number;
                    }

                    if(sentVideoStats) {
                        videoTelemetry.rtt.videoSent = getMetricSummary(sentVideoStats.pairRttInMs?.aggregation!);
                        videoTelemetry.packets.videoSent = sentVideoStats.packetsPerSecond?.aggregation?.sum[0] as number;
                        videoTelemetry.packets.videoSentLost = sentVideoStats.packetsLostPerSecond?.aggregation?.sum[0] as number;
                    }

                    if(receivedVideoStats) {
                        videoTelemetry.jitter.video = getMetricSummary(receivedVideoStats.jitterInMs?.aggregation!);
                        videoTelemetry.rtt.videoReceived = getMetricSummary(receivedVideoStats.pairRttInMs?.aggregation!);
                        videoTelemetry.packets.videoReceived = receivedVideoStats.packetsPerSecond?.aggregation?.sum[0] as number;
                        videoTelemetry.packets.videoReceivedLost = receivedVideoStats.packetsLostPerSecond?.aggregation?.sum[0] as number;
                    }
                });
            }
    
            const inCallDiagnostics:InCallDiagnostics = await this._preCallResults.inCallDiagnostics;
            
            if(collector) {
                collector.dispose();
            }
        
            if(inCallDiagnostics.connected) {
                audioCallResults.callSuccess = inCallDiagnostics.connected;
                audioCallResults.status = 'Complete';
                audioCallResults.successMessage = "Audio Call test complete";
                audioCallResults.networkMediaTelemetry = audioTelemetry;

                videoCallResults.callSuccess = inCallDiagnostics.connected;
                videoCallResults.status = 'Complete';
                videoCallResults.successMessage = "Video Call test complete";
                videoCallResults.networkMediaTelemetry = videoTelemetry;
            }
            else {
                audioCallResults.status = 'Fail';
                audioCallResults.successMessage = "Call failed to connect";

                videoCallResults.status = 'Fail';
                videoCallResults.successMessage = "Call failed to connect";
            }
        }
        catch (e) {
            audioCallResults.status = "Fail";
            audioCallResults.error = e as NetworkTestError;

            videoCallResults.status = "Fail";
            videoCallResults.error = e as NetworkTestError;
        }
        finally {
            this._eventEmitter.emit('testProgress', audioCallResults);
            this._eventEmitter.emit('testProgress', videoCallResults);
        }
        return {
            audio: audioCallResults,
            video: videoCallResults
        }
    }
}

export default PreCallApiToNetworkTestAdapter;