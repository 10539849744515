import { TooltipHost } from "@fluentui/react";

interface ResultsTooltipProps {
  label: string;
  tooltipContent: string;
}

function ResultsTooltip(props: ResultsTooltipProps) {
  return (
    <TooltipHost content={props.tooltipContent}>
      <u className="underlined">{props.label}</u>
    </TooltipHost>
  );
}

export default ResultsTooltip;