import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { useLocale } from '../localization';
import { NetworkTestProgress } from '../networkTest/public';
// import { NetworkTestProgress, NetworkTestStep } from '../networkTest/public';
// import { formatString } from '../utils/LocalizationUtils';

export interface ProgressBarStrings {
  progressIndicatorLabel: string,
  progressIndicatorDescription: string,
  browserTestName: string,
  devicesTestName: string,
  audioTestName: string,
  videoTestName: string,
}

interface ProgressBarProps {
  showProgressBar: boolean,
  currentTestProgress: NetworkTestProgress[]
}


//TODO: Figure out how to display which tests are running
function ProgressBar(props: ProgressBarProps) {
  const strings = useLocale().strings.progressBar;
  const showProgressBar = props.showProgressBar;
  // const currentTestProgress:NetworkTestProgress[] = props.currentTestProgress;
  // let tests = "";

  // for(let item of currentTestProgress) {
  //   if(item.step === NetworkTestStep.Init || item.step === NetworkTestStep.Done) {
  //     break;
  //   }
    
  //   if(item.status === "Running") {
  //     if(item.step === NetworkTestStep.Browser) {
  //       tests += strings.browserTestName + ", ";
  //     }
  //     if(item.step === NetworkTestStep.MediaDevices) {
  //       tests += strings.devicesTestName + ",";
  //     }
  //     if(item.step === NetworkTestStep.VideoCall) {
  //       tests += strings.videoTestName + ",";
  //     }
  //     if(item.step === NetworkTestStep.AudioCall) {
  //       tests += strings.audioTestName + ",";
  //     }
  //   }
  // }

  // if(tests && tests.length > 2) {
  //   tests = tests.substring(0, tests.length - 2);
  // }
  // const description = tests !== "" ? formatString(strings.progressIndicatorDescription, {testName: tests}) : "Initializing Tests";
  const description = "";


  if (showProgressBar) {
    return (
      <div className="ms-Grid" dir="ltr">
        <div id="progressBar" className="ms-Grid-row">
            <ProgressIndicator label={strings.progressIndicatorLabel}
                description={description} 
            />
        </div>
      </div>
    );
  }
  return (null);
}

export default ProgressBar;
