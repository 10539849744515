import { CallClient, DeviceAccess, DeviceManager, Features, PreCallDiagnosticsResult } from "@azure/communication-calling";
import { CommunicationTokenCredential } from "@azure/communication-common";
import EventEmitter from "events";
import { NetworkTestStarted, NetworkTestStep, NetworkTestDone, NetworkTestResults, NetworkTestError, BrowserTest, MediaDevicesTest, CallTestResults } from "./public";
import PreCallApiToNetworkTestAdapter from "./PreCallApiToNetworkTestAdapter";

class NetworkTestRunner {
    private _client: CallClient;
    private _eventEmitter: EventEmitter;

    constructor(client: CallClient, eventEmitter: EventEmitter) {
        this._client = client;
        this._eventEmitter = eventEmitter;
    }

    private async checkDevicePermissions():Promise<DeviceAccess> {
        const deviceManager:DeviceManager = await this._client.getDeviceManager();
         return await deviceManager.askDevicePermission({
            audio: true,
            video: true
        });
    }

    public async checkNetworkTestRunnable():Promise<boolean> {
        const deviceAccess = await this.checkDevicePermissions();
        if(!(deviceAccess.audio || deviceAccess.video)) {
            return false;
        }
        else {
            return true;
        }
    }

    public async runNetworkTest(communicationTokenCredential: CommunicationTokenCredential) {
        const networkTestStarted: NetworkTestStarted = {
            step: NetworkTestStep.Init,
            status: 'Running',
        };
        const networkTestComplete: NetworkTestDone = {
            step: NetworkTestStep.Done,
            status: 'Pending',
        };
        this._eventEmitter.emit('testProgress', Object.assign({}, networkTestStarted));
        const results:NetworkTestResults = this.getDefaultNetworkTestResults();

        try {
            const preCallResults:PreCallDiagnosticsResult = await this._client.feature(Features.PreCallDiagnostics).startTest(communicationTokenCredential);
            networkTestStarted.status = "Complete"; //Initialization is completed
            networkTestStarted.id = preCallResults.id;
            this._eventEmitter.emit('testProgress', Object.assign({}, networkTestStarted));
            const networkTestResults:PreCallApiToNetworkTestAdapter = new PreCallApiToNetworkTestAdapter(this._eventEmitter, preCallResults);

            networkTestStarted.status = "Complete";
            networkTestStarted.id = networkTestResults.getTestId();
            this._eventEmitter.emit('testProgress', networkTestStarted);

            //Start the Results adaptation in parallel
            const callTestResultsPromise:Promise<CallTestResults> = networkTestResults.getCallTestResults();
            const browserTestResultsPromise:Promise<BrowserTest> = networkTestResults.getBrowserTestResults();
            const devicesTestResultsPromise:Promise<MediaDevicesTest> = networkTestResults.getMediaDevicesTestResults();

            results.id = networkTestResults.getTestId();

            //Await all 3 tasks to finish running
            results.browser = await browserTestResultsPromise;
            results.mediaDevices = await devicesTestResultsPromise;

            const callTestResults = await callTestResultsPromise;

            results.audio = callTestResults.audio;
            results.video = callTestResults.video;

            networkTestComplete.status = "Pass";
        }
        catch(e) {
            networkTestComplete.status = "Fail";
            networkTestComplete.error = e as NetworkTestError;
        }
        finally {
            this._eventEmitter.emit('testProgress', networkTestComplete);
            return results;
        }
    }

    public on(event: 'testProgress', listener: any) {
        this._eventEmitter.on(event, listener);
    }

    public off(event: 'testProgress', listener: any) {
        this._eventEmitter.off(event, listener);
    }

    /**
     * @returns default NetworkTestResults object
     */
     private getDefaultNetworkTestResults(): NetworkTestResults {
        return {
            id: undefined,
            browser: {
                step: NetworkTestStep.Browser,
                status: 'Skipped',
                name: 'unknown',
                version: 'unknown',
                os: 'unknown',
                engine: 'unknown',
                formFactor: 'unknown'
            },
            mediaDevices: {
                step: NetworkTestStep.MediaDevices,
                status: 'Skipped',
                camera: {
                    available: false
                },
                microphone: {
                    available: false
                },
                speaker: {
                    available: false
                }
            },
            audio: {
                step: NetworkTestStep.AudioCall,
                status: 'Skipped',
                callSuccess: false
            },
            video: {
                step: NetworkTestStep.VideoCall,
                status: 'Skipped',
                callSuccess: false
            }
        }
    }
}

export default NetworkTestRunner