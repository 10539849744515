import { createContext, useContext } from 'react';
import { AppHeaderStrings } from '../components/AppHeader';
import { BrowserTestResultsStrings } from '../components/results/BrowserTestResultsPane';
import { CallTestResultsStrings, MetricSummaryResultStrings, PacketResultStrings } from '../components/results/CallTestResultsPane';
import { DeviceTestResultsStrings } from '../components/results/DeviceTestResultsPane';
import { NetworkDiagnosticScreenStrings } from '../components/NetworkDiagnosticScreen';
import { ProgressBarStrings } from '../components/ProgressBar';
import { TestCaseStrings } from '../components/TestCase';
import { APP_LOCALE_EN_US } from './locales';
import { AppFooterStrings } from '../components/AppFooter';
import { CaptchaAreaStrings } from '../components/CaptchaArea';

export interface AppLocale {
    strings: AppStrings;
}

export interface AppStrings {
    appHeader: AppHeaderStrings;
    appFooter: AppFooterStrings;
    diagnosticTestScreen: NetworkDiagnosticScreenStrings;
    progressBar: ProgressBarStrings;
    testCase: TestCaseStrings;
    browserTestResults: BrowserTestResultsStrings;
    callTestResults: CallTestResultsStrings;
    deviceTestResults: DeviceTestResultsStrings;
    metricSummaryResult: MetricSummaryResultStrings;
    packetResult: PacketResultStrings;
    captchaArea: CaptchaAreaStrings;
}

export const LocaleContext = createContext<AppLocale>(APP_LOCALE_EN_US);

/**
 * @private
 */
 export const useLocale = (): AppLocale => useContext(LocaleContext);