import { useLocale } from "../../localization";
import { formatString } from "../../utils/LocalizationUtils";

type BrowserDetails = {
  engine: string;
  os: string;
  version: string;
};

interface BrowserTestResultsDisplayProps {
  browserDetails: BrowserDetails;
}

export interface BrowserTestResultsStrings {
  engineLabel: string;
  osLabel: string;
  versionLabel: string;
}

function BrowserTestResultsPane(props: BrowserTestResultsDisplayProps) {
  const browserDetails: BrowserDetails = props.browserDetails;
  const strings = useLocale().strings.browserTestResults;
  return (
    <div>
      <ul className="no-bullets">
        <li>{formatString(strings.engineLabel, {engine: browserDetails.engine})}</li>
        <li>{formatString(strings.osLabel, {os: browserDetails.os})}</li>
        <li>{formatString(strings.versionLabel, {version: browserDetails.version})}</li>
      </ul>
    </div>
  );
}

export default BrowserTestResultsPane;
