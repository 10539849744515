import { CallEndReason, CommunicationServicesError } from "@azure/communication-calling";

/**
 * Order of network test progresses.
 * @beta
 */
 export enum NetworkTestStep {
    Init,
    Browser,
    MediaDevices,
    AudioCall,
    VideoCall,
    Done
}

/**
 * @beta
 */
 export type NetworkTestProgress =
    NetworkTestStarted |
    BrowserTest |
    MediaDevicesTest |
    CallTest |
    NetworkTestDone;

/**
 * Current status of a network test
 * @beta
 */
 export type TestStatus = 'Pending' | 'Running' | 'Pass' | 'Complete' | 'Fail' | "Skipped";

 /**
 * Network test error. Reason of why a specific test failed.
 * @beta
 */
export type NetworkTestError = CommunicationServicesError;

/**
 * Network test base event payload. All network test event payloads have these properties
 * @beta
 */
 export interface NetworkTestBase {
    step: NetworkTestStep,
    status: TestStatus,
    successMessage?: string,
    error?: NetworkTestError
}

/**
 * Network test started event payload.
 * @beta
 */
 export interface NetworkTestStarted extends NetworkTestBase {
    readonly step: NetworkTestStep.Init;
    id?: string;
}

/**
 * Browser test event payload
 * @beta
 */
 export interface BrowserTest extends NetworkTestBase {
    readonly step: NetworkTestStep.Browser
    supported?: boolean;
    name: string;
    engine: string;
    version: string;
    os: string;
    formFactor: string;
}

/**
 * Media Devices test event payload
 * @beta
 */
 export interface MediaDevicesTest extends NetworkTestBase {
    readonly step: NetworkTestStep.MediaDevices;
    camera: {
        available?: boolean;
    }
    microphone: {
        available?: boolean;
    };
    speaker: {
        available?: boolean;
    }
}

/**
 * Call test event payload
 * @beta
 */
 export interface CallTest extends NetworkTestBase {
    readonly step: NetworkTestStep.AudioCall | NetworkTestStep.VideoCall
    callId?: string,
    callEndReason?: CallEndReason;
    // Success entails successfully established and disconnected
    callSuccess: boolean,
    networkMediaTelemetry?: NetworkMediaTelemetry
}

export interface CallTestResults {
    audio: CallTest,
    video: CallTest
}

/**
 * Network test done event payload.
 * @beta
 */
 export interface NetworkTestDone extends NetworkTestBase {
    readonly step: NetworkTestStep.Done;
    // networkTestResults: NetworkTestResults;
}

/**
 * Statistical measures of each media metric
 * @beta
 */
 export interface MetricSummary {
    mean: number,
    minimum: number,
    maximum: number,
}

export interface NetworkTestResults {
    id: string | undefined;
    browser: BrowserTest;
    mediaDevices: MediaDevicesTest;
    audio: CallTest;
    video: CallTest;
}

/**
 * The media metrics calculated in the test calls
 * @beta
 */
export interface NetworkMediaTelemetry {
    jitter: {
        audio?: MetricSummary;
        video?: MetricSummary;
        unit: string;
    },
    rtt: {
        audioSent?: MetricSummary;
        audioReceived?: MetricSummary;
        videoSent?: MetricSummary;
        videoReceived?:MetricSummary;
        unit: string;
    },
    packets: {
        audioSent?: number;
        audioSentLost?: number;
        audioReceived?: number;
        audioReceivedLost?: number;
        videoSent?: number;
        videoSentLost?: number;
        videoReceived?: number;
        videoReceivedLost?: number;
    },
    streamSize? : {
        videoSent: { width: number, height: number }[];
    }
}