import config from "../config.json";
import axios from "axios";

export type ChallengeType = "audio" | "visual";

export interface CaptchaResponse {
  challengeId: string;
  challengeType: ChallengeType;
  challengeString: string;
}

export const fetchCaptcha = async (type: ChallengeType): Promise<any> => {
  const url = `${config.apiUrl}/captcha/${type}`;
  const response = await axios.get(url);
  return response.data;
};

export const solveCaptcha = async (type: ChallengeType, id: string, answer: string): Promise<any> => {
  const url = `${config.apiUrl}/captcha/${type}/solution`;

  try {
    const response = await axios.post(url, {
      "ChallengeId": id,
      "InputSolution": answer,
    });

    const reason = response.data.Reason;
    const output = {
      solved: response.data.Solved,
      error: ""
    };

    if (reason === "WrongAnswer") {
      output.error = "Incorrect answer. Try again.";
    }
    else if (reason === "NoChallengeSession") {
      output.error = "Invalid captcha - Please generate a new captcha.";
    }
    return output;
  } catch (err) {
    return {
      solved: false,
      error: "Server error."
    };
  }
};
