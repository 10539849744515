import { useLocale } from "../localization";
import TestResults from "./results/TestResults";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Icon } from '@fluentui/react/lib/Icon';
import { NetworkTestProgress } from "../networkTest/public";

initializeIcons();

interface TestCaseProps {
  name: string;
  description: string;
  testCaseProgress?: NetworkTestProgress;
}

export interface TestCaseStrings {
  notStarted: string;
}

function TestCase(props: TestCaseProps) {
  const strings = useLocale().strings.testCase;
  //TODO: The result from the server should be matched to a string in the locale json
  let status = props.testCaseProgress?.status ? props.testCaseProgress.status : strings.notStarted;

  return (
    <div id={props.name} className="ms-Grid-row">
      <TestDescription name={props.name} description={props.description} />
      <TestStatus status={status} />
      <TestResults results={props.testCaseProgress} />
    </div>
  );
}

interface TestStatusProps {
  status: string;
}

//using MS Fluent UI Grid css classes for layout
function TestStatus(props: TestStatusProps) {
  return (
    <div className={`ms-Grid-col ms-sm3 ms-lg2 ms-xl1`}>
      <TestStatusIcon status={props.status} />
      <div className="test-status">
        {props.status}
      </div>
    </div>
  );
}

function TestStatusIcon(props: TestStatusProps) {
  switch(props.status) {
    case "Complete":
    case "Pass":
      return (
        <Icon className="statusIcon pass" iconName="SkypeCircleCheck" />
      );

    case "Fail":
      return (
        <Icon className="statusIcon fail" iconName="StatusErrorFull" />
      );

    default:
      return null;
  }
}

interface TestDescriptionProps {
  name: string,
  description: string
}

function TestDescription (props: TestDescriptionProps) {
  //using MS Fluent UI Grid css classes for layout
  return (<div className="ms-Grid-col ms-sm4 ms-lg3 ms-xl2">
    <div>{props.name}</div>
    <div className="secondary-text">{props.description}</div>
  </div>);
}

export default TestCase;
