import React from "react";

// regex to search for the pattern "\{\}"
const placeholdersRegex = /{(\w+)}/g;

/**
* @internal
*/
export interface _IObjectMap<T> {
    [key: string]: T;
}

/**
 * Inserts variables into a template string to create a formatted string
 *
 * @param str - The string to be formatted
 * @param vars - Variables to use to format the string
 * @returns formatted string
 */
export const formatString = (str: string, vars: _IObjectMap<string>): string => {
    if (!str) {
      return "";
    }
    if (!vars) {
      return str;
    }
  
    return str.replace(placeholdersRegex,(_: string, k: string) => vars[k] || `{${k }}`);
};

/**
 * Creates a string with inline JSX elements
 *
 * @param str - The string to be formatted
 * @param vars - Variables to use to format the string
 * @returns react fragment containing string with inline JSX elements
 */
 export const formatStringWithInlineElements = (str: string, vars: _IObjectMap<JSX.Element | string>): JSX.Element => {
  if (!str) {
    return <React.Fragment>{""}</React.Fragment>;
  }
  if (!vars) {
    return <React.Fragment>{""}</React.Fragment>;
  }

  const regex = RegExp(placeholdersRegex);
  const elements:(string | JSX.Element)[] = [];
  let prev = 0;
  let array = regex.exec(str);
  while (array !== null) {
    if (prev !== array.index) {
      elements.push(str.substring(prev, array.index));
    }
    
    let val = vars[array[1]] !== undefined ?  vars[array[1]] : array[0];
    elements.push(val);
    prev = regex.lastIndex;
    array = regex.exec(str);
  }
  elements.push(str.substring(prev));
  return <React.Fragment>{elements}</React.Fragment>;
};