import "./App.css";
import AppHeader from "./components/AppHeader";
import NetworkDiagnosticScreen from "./components/NetworkDiagnosticScreen";
import AppFooter from "./components/AppFooter";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./components/appInsights/AppInsights";


function App() {
  return (
    <div className="App">
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppHeader />
        <NetworkDiagnosticScreen />
        <AppFooter />
      </AppInsightsContext.Provider>
    </div>
  );
}

export default App;
